@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "react-spring-bottom-sheet/dist/style.css";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  font-family: 'Outfit', sans-serif;
}
html, body, #root, .Nina-App, .menu-screen{
  /*scroll-behavior: smooth;*/
  overscroll-behavior: none!important;
}

* {
  /*outline: 1px solid limegreen !important;*/
}

:root {
  --theme-primary-color: #007eb6;
  --theme-grey-color: #858992;
  --theme-orange-color: #0FC000;
  --theme-walls-indentation: 15px;
  --theme-shadow: 0 4px 12px #f1f4f9;
  --theme-fz-text: 12pt;
  --theme-fz-title-1: 18pt;
  --theme-fz-title-2: 16pt;
  --theme-modded-bg-color: #fff;
  --theme-modded-fore-color: #000;
  --theme-modded-sticky-bg: rgba(255, 255, 255, 0.8);
  --theme-modded-card-bg: #fcfeff;
  --theme-gradient: linear-gradient(267.55deg, #000B27 0%, #184C70 100%);
  --theme-sky-blue: #419BDC;
}
@media screen and (min-width: 640px) {
  :root{
    --rsbs-max-w: 640px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
}

.bg-with-gradient{
  background: linear-gradient(267.55deg, #000B27 0%, #184C70 100%);
}
.primary-color{
  color: var(--theme-primary-color);
}

.fore-color{
  color: var(--theme-modded-fore-color);
}
.card-modded-bg{
  background: var(--theme-modded-card-bg)
}

.theme-shadow{
  box-shadow: var(--theme-shadow);
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.animate {
  animation : shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

.indent {
  padding-left: var(--theme-walls-indentation);
  padding-right: var(--theme-walls-indentation);
}
.Nina-App.mode-dark{
  background: var(--theme-modded-bg-color);
}

.no-scroll-screen{
  overflow: hidden;
  height: 100vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.ReactModal__Body--open {
  overflow-y: hidden;
}
body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}

.swiper-button-next {
  background-image: url("./../images/arrow_right_slider.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 55px;
  height: 55px;
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev {
  background-image: url("./../images/arrow_left_slider.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 55px;
  height: 55px;
}

.swiper-button-prev::after {
  display: none;
}


.screenTransition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.screenTransition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.screenTransition-exit {
  opacity: 1;
}
.screenTransition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.pincode-input-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 15px!important;
}
.pincode-input-text{
  border-color: #ccc;
  border-radius: 15px;
  height: 55px;
  width: 55px;
  margin: 0;

}
@media screen and (max-width: 600px) {
  .pincode-input-text{
    border-radius: 10px;
    height: 45px!important;
    width: 45px!important;
  }
}
#otp-screen .pincode-input-container {
  width: 100%;
}
[data-rsbs-overlay], [data-rsbs-backdrop], [data-rsbs-root]:after{
  z-index: 4;
}

.PhoneInputInput{
  border: solid thin #ccc;
  border-radius: 10px;
  height: 65px;
  margin: 5px 0;
  padding-left: 10px;
  font-size: 20px;
}
.small .PhoneInputInput{
  height: 35px;
}
.PhoneInput--focus .PhoneInputInput{
  outline: none;
  border: solid thin var(--theme-sky-blue);
}

.pincode-input-container .input-style{
  width: 3rem !important;
  height: 3rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0 / 30%);
  padding: 0;
}

.card-panel{
  border-radius: 21px;
  overflow: hidden;
  box-shadow: 5px 10px 20px rgba(211, 209, 216, 0.3);
}
