.menu-content-wrapper{
   max-width: 1400px;
   padding: 30px 100px;
   margin: auto;
   display: grid;
   gap: 20px;
   grid-template-columns: 5fr 2fr;
   grid-template-areas:
   "content sidebar";
   &.overlay-open{
      height: 100%;
      overflow: auto;
   }
   .menu-content{
      grid-area: content;
      //height: 300px;
   }
   .menu-sidebar{
      grid-area: sidebar;
      height: 80vh;
      position: -webkit-sticky;
      position: sticky;
      top: 150px;
      overflow-y: scroll;
      scrollbar-width: none;
      padding: 5px;
      &::-webkit-scrollbar {
         width: 1px;
      }
   }

   @media screen and(max-width: 960px) {
      grid-template-columns: 1fr;
      grid-template-areas:
          "sidebar"
          "content"
   ;
      gap: 0;
      .menu-sidebar{
         height: auto;
         position: static;
      }

   }
   @media screen and(max-width: 1300px) {
      padding: 30px 15px;
   }
}
