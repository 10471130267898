.splash-screen{
   height: 100vh;
   width: 100vw;
   display: flex;
   align-items: center;
   justify-content: center;
   background: var(--theme-primary-color);
   .splash-logo{
      margin: auto;
   }
}
